
import {
  defineComponent,
} from 'vue';
import {
  orderState, getOrders, setCurrentOrder,
} from '../composables/Orders';
import Ellipsis from '@/modules/testDrive/components/Ellipsis.vue';

export default defineComponent({
  components: {
    Ellipsis,
  },
  setup() {
    const handleShowConfirmDialog = (item: any) => {
      setCurrentOrder(item);
      orderState.orderConfirmDialogVisible = true;
    };

    const handlePageChange = (page: number) => {
      getOrders({ page });
    };

    return {
      orderState,
      handleShowConfirmDialog,
      handlePageChange,
    };
  },
});
