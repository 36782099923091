
import {
  defineComponent,
  ref,
  Ref,
  h,
  watch,
  nextTick,
} from 'vue';
import { ElMessageBox } from 'element-plus';

export default defineComponent({
  name: 'Ellipsis',
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isEllipsis = ref(false);
    const elemRef = ref<HTMLElement | null>(null);

    // 检查行高是否溢出
    const checkEllipsis = (elem: HTMLElement) => elem.scrollHeight > elem.clientHeight;

    // 更新文本数据
    const updateEl = (selectEl: HTMLElement | null, isEl: Ref<boolean>) => {
      if (!selectEl) return;
      isEl.value = checkEllipsis(selectEl);
    };

    // 需要超出隐藏并设置为回调点击时
    const showMore = () => {
      ElMessageBox({
        title: '跟进备注',
        message: h('p', null, [
          h('span', { style: { wordBreak: 'break-all' } }, props.content),
        ]),
        confirmButtonText: '关 闭',
      });
    };

    watch(() => props.content, (n) => {
      if (!n) return;
      nextTick(() => {
        updateEl(elemRef.value, isEllipsis);
      });
    }, { immediate: true });

    return {
      isEllipsis,
      showMore,
      elemRef,
    };
  },
});
