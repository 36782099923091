import { reactive, ref, toRaw } from 'vue';
import { ElMessage } from 'element-plus';
import { getTestDriveOrderList } from '../service.config';
import { SourceTypeDict, SourceType } from '@/modules/order/page.config';

// 订单状态
export const orderState = reactive({
  searchOptions: {
    page: 1,
    count: 20,
    mobile_number: '',
    submit_time: [],
  },
  totalCount: 0,
  tableData: [],
  currentOrder: 0,
  orderConfirmDialogVisible: false,
});

export const confirmForm: any = reactive({
  models: [],
  test_drive_at: '',
  date_types: [],
  remark: '',
});

export function handleResetSearchOptions() {
  const { searchOptions } = orderState;
  searchOptions.page = 1;
  searchOptions.count = 20;
  searchOptions.mobile_number = '';
  searchOptions.submit_time = [];
}

export async function getOrders(params?: any) {
  const queryParams = {
    ...toRaw(orderState.searchOptions),
    ...params,
  };

  // 重新赋值
  orderState.searchOptions = reactive(queryParams);

  const newQueryParams = { ...queryParams };
  if (newQueryParams.submit_time && newQueryParams.submit_time.length) {
    const [created_from, created_to] = newQueryParams.submit_time;
    newQueryParams.created_from = created_from || '';
    newQueryParams.created_to = created_to || '';
  }

  delete newQueryParams.submit_time;

  const {
    code,
    data: {
      orders = [],
      total: totalCount,
    } = {},
    message,
  } = await getTestDriveOrderList(newQueryParams);
  if (code !== 0) {
    ElMessage.error(message);
    return;
  }
  const formatData = orders
    .map((item: any) => ({
      ...item,
      user: {
        ...item.user,
        source_origin: SourceTypeDict[item.user?.source_type as SourceType],
      },
    }));

  orderState.tableData = reactive(formatData);
  orderState.totalCount = ref(totalCount);
}

export const testDriveTimeType = [
  {
    key: 1,
    value: [1],
  },
  {
    key: 2,
    value: [2],
  },
  {
    key: 3,
    value: [1, 2],
  },

];

export function getTestDriveTimeTypeOriginal(type: number) {
  return testDriveTimeType.find((el) => (el.key === type))?.value || [];
}

export function setCurrentOrder(row: any) {
  const {
    test_drive_time_type = 1,
    test_drive_at = '',
    models = '',
    remark = '',
    id,
  } = row;
  confirmForm.test_drive_at = test_drive_at || '';
  confirmForm.models = models.split(';')?.filter((el: string) => (el)) ?? [];
  confirmForm.date_types = getTestDriveTimeTypeOriginal(test_drive_time_type);
  confirmForm.remark = remark;
  orderState.currentOrder = id;
}

export default {};
