
import { defineComponent } from 'vue';
import { handleResetSearchOptions, getOrders, orderState } from '../composables/Orders';

export default defineComponent({
  setup() {
    const handleSearchBtnClick = () => {
      getOrders({ page: 1 });
    };
    const defaultTime = [
      new Date(2000, 1, 1, 0, 0, 0),
      new Date(2000, 2, 1, 23, 59, 59),
    ];

    return {
      orderState,
      handleSearchBtnClick,
      handleResetSearchOptions,
      defaultTime,
    };
  },
});
