import { rest, ResponseData } from '@/ajax';

interface GetTestDriveListParams {
  page: number,
  count: number,
  mobile_number?: string,
  created_from?: string,
  created_to?: string,
}

export async function getTestDriveOrderList(params: GetTestDriveListParams):
Promise<ResponseData<any>> {
  return rest.testDrive.getTestDriveOrderList(params);
}

export async function testDriveTrace(orderId: number,
  params: { test_drive_at: string, model: string[], date_type: string[] }) {
  return rest.testDrive.testDriveTrace(orderId, params);
}

export default {};
