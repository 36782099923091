import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_options = _resolveComponent("search-options")!
  const _component_lx_search_panel = _resolveComponent("lx-search-panel")!
  const _component_order_tables = _resolveComponent("order-tables")!
  const _component_lx_container = _resolveComponent("lx-container")!
  const _component_order_trace_dialog = _resolveComponent("order-trace-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_lx_search_panel, null, {
      default: _withCtx(() => [
        _createVNode(_component_search_options)
      ]),
      _: 1
    }),
    _createVNode(_component_lx_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_order_tables)
      ]),
      _: 1
    }),
    _createVNode(_component_order_trace_dialog)
  ]))
}