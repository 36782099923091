import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d5c2701"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ellipsis-wrap" }
const _hoisted_2 = {
  class: "ellipsis-content",
  "line-clamp": "3",
  ref: "elemRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isEllipsis)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: "ellipsis-btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showMore && _ctx.showMore(...args)))
          }))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.content), 1)
    ], 512)
  ]))
}