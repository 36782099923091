
import {
  defineComponent, reactive, toRaw,
} from 'vue';
import { ElMessage } from 'element-plus';
// eslint-disable-next-line import/named
import { confirmForm, orderState, getOrders } from '../composables/Orders';
import { testDriveTrace } from '../service.config';

export default defineComponent({
  setup() {
    const carModels = reactive([
      {
        label: 'UX300e',
        value: 1,
      },
      {
        label: 'NX400h+',
        value: 2,
      },
    ]);

    const dateTypes = reactive([
      {
        label: '上午',
        value: 1,
      },
      {
        label: '下午',
        value: 2,
      },
    ]);

    const disabledDate = (time: Date) => time.getTime() < Date.now() - 24 * 3600 * 1000;

    const testDriveTraceConfirm = async () => {
      if (!confirmForm.models.length) {
        ElMessage.error('请选择意向试驾车型');
        return;
      }

      if (!confirmForm.test_drive_at) {
        ElMessage.error('请选择预计试驾时间');
        return;
      }

      if (!confirmForm.date_types.length) {
        ElMessage.error('请选择预计试驾时间');
        return;
      }

      const { code, message } = await testDriveTrace(orderState.currentOrder, toRaw(confirmForm));

      if (code !== 0) {
        ElMessage.error(message);
        return;
      }

      ElMessage({
        message: '跟进标记操作成功',
        type: 'success',
      });

      orderState.orderConfirmDialogVisible = false;
      confirmForm.test_drive_at = '';
      confirmForm.models = [];
      confirmForm.date_types = [];
      // 刷新数据
      await getOrders({ page: 1 });
    };

    return {
      orderState,
      confirmForm,
      carModels,
      dateTypes,
      disabledDate,
      testDriveTraceConfirm,
    };
  },
});
